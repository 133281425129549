import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  AppBar, 
  Toolbar, 
  Button 
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // <-- Importamos el ícono de WhatsApp
import casa1 from '../images/casa1.jpg';

const VentasPage = () => {
  // Mensaje de ejemplo que se enviará por WhatsApp
  const whatsappMessage = "Hola, estoy interesado en TERRENO EN VENTA ubicado en Carretera Tal-Mex km 45+800, Ocoyacac, Edo de Mex. Por favor, envíame más información.";
  const whatsappLink = "https://wa.me/5217221424284?text=" + encodeURIComponent(whatsappMessage);

  return (
    <>
      {/* Barra de navegación */}
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Ventas
          </Typography>
          <Button onClick={() => window.location.href = '/'} color="inherit">
            Inicio
          </Button>
        </Toolbar>
      </AppBar>

      {/* Imagen de encabezado */}
      <Box
        sx={{
          height: '60vh',
          background: `url('${casa1}') center/cover no-repeat`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: 300, mb: 2 }}>
            TERRENO EN VENTA
          </Typography>
          <Typography variant="h6">
            Ocoyacac, Edo de Mex.
          </Typography>
        </Box>
      </Box>

      {/* Contenido principal */}
      <Container sx={{ py: 6 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Detalles del Predio
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Superficie:</strong> 2,957 m²
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Descripción y detalles:</strong> Propiedad privada que se acredita con contrato privado de compraventa, traslado de dominio y clave catastral. Excelente ubicación y orientación, con 1 frente de 48m sobre carril de desaceleración entre 2 retornos subterráneos con uso de suelo comercial. Ubicada sobre la carretera México - Toluca, a 15 minutos del aeropuerto y de Santa Fe, con conexión a la autopista Valle de Bravo. Desarrollos cercanos: Mexico Drive Resort, Off Road México, BMW, KAVAK, Autonzone, Ducati, Polaris, entre otros. También se encuentra próximo a desarrollos habitacionales como Los Encinos Club de Golf, Hacienda Jalapa, San Martín y Reserva Santa Fe. Educación cercana: Prepa IBERO y Colegio Miraflores.
                </Typography>

                <Typography variant="h5" gutterBottom>
                  Ubicación
                </Typography>
                <Box sx={{ width: '100%', height: 300, mb: 2 }}>
                  <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://www.openstreetmap.org/export/embed.html?bbox=-99.30%2C18.94%2C-99.28%2C18.96&amp;layer=mapnik&amp;marker=18.95,-99.29"
                    title="Mapa de Ubicación"
                  ></iframe>
                </Box>

                <Typography variant="h5" gutterBottom>
                  Precio
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Precio total:</strong> $10,349,500 Pesos Mex. / $574,972 USD.
                </Typography>

                {/* Botón de WhatsApp con ícono oficial de Material UI */}
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <Button
                    variant="contained"
                    startIcon={
                      <WhatsAppIcon 
                        sx={{ fontSize: '24px' }} 
                      />
                    }
                    onClick={() => window.open(whatsappLink, '_blank')}
                    sx={{
                      backgroundColor: '#25D366',
                      color: '#fff',
                      fontWeight: 'bold',
                      borderRadius: '9999px',
                      padding: '12px 24px',
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      '&:hover': {
                        backgroundColor: '#20b357',
                      },
                    }}
                  >
                    Contáctame y hablemos
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default VentasPage;
