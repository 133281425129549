import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  AppBar, 
  Toolbar, 
  Button,
  IconButton,
  Paper,
  Fade,
  Zoom,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FactoryIcon from '@mui/icons-material/Factory';
import NatureIcon from '@mui/icons-material/Nature';
import StoreIcon from '@mui/icons-material/Store';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import casa1 from '../images/casa1.jpg';
import whats from '../images/whats.jpg';
import logo from '../images/logo.png';

const VentasPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    email: '',
    ubicacion: '',
    presupuesto: '',
    tipoUso: '',
    descripcion: ''
  });
  
  const categories = [
    { 
      name: 'Residencial', 
      icon: <HomeIcon sx={{ fontSize: 48 }} />, 
      color: '#4CAF50', 
      bgColor: '#E8F5E9',
      description: 'Terrenos para casas y desarrollos habitacionales'
    },
    { 
      name: 'Industrial', 
      icon: <FactoryIcon sx={{ fontSize: 48 }} />, 
      color: '#2196F3', 
      bgColor: '#E3F2FD',
      description: 'Espacios ideales para fábricas y bodegas'
    },
    { 
      name: 'Rústicos', 
      icon: <NatureIcon sx={{ fontSize: 48 }} />, 
      color: '#FF9800', 
      bgColor: '#FFF3E0',
      description: 'Terrenos campestres y agrícolas'
    },
    { 
      name: 'Comerciales', 
      icon: <StoreIcon sx={{ fontSize: 48 }} />, 
      color: '#9C27B0', 
      bgColor: '#F3E5F5',
      description: 'Ubicaciones perfectas para negocios'
    }
  ];

  const searchOption = { 
    name: 'Describe el terreno que necesitas', 
    shortName: 'Buscar terreno específico',
    icon: <DescriptionIcon sx={{ fontSize: 48 }} />, 
    color: '#E91E63', 
    bgColor: '#FCE4EC',
    description: 'Cuéntanos en detalle qué tipo de terreno estás buscando y te ayudamos a encontrarlo'
  };

  const tiposUso = [
    'Residencial',
    'Comercial',
    'Industrial',
    'Mixto',
    'Otro'
  ];

  const residentialProperties = [
    {
      id: 1,
      title: "TERRENO EN VENTA",
      location: "Ocoyacac, Edo de Mex.",
      surface: "2,957 m²",
      description: "Propiedad privada que se acredita con contrato privado de compraventa, traslado de dominio y clave catastral. Excelente ubicación y orientación, con 1 frente de 48m sobre carril de desaceleración entre 2 retornos subterráneos con uso de suelo comercial.",
      price_mxn: "10,349,500",
      price_usd: "574,972",
      image: casa1,
      whatsappMessage: "Hola, estoy interesado en TERRENO EN VENTA ubicado en Carretera Tal-Mex km 45+800, Ocoyacac, Edo de Mex. Por favor, envíame más información.",
      googleEarthUrl: "https://earth.google.com/earth/d/1tNxLknJY2EUVzsU1_o6Dvd9etxeHglas?usp=sharing"
    }
  ];

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedProperty(null);
  };

  const handlePropertySelect = (property) => {
    setSelectedProperty(property);
  };

  const handleBack = () => {
    if (selectedProperty) {
      setSelectedProperty(null);
    } else {
      setSelectedCategory(null);
      setFormData({
        nombre: '',
        telefono: '',
        email: '',
        ubicacion: '',
        presupuesto: '',
        tipoUso: '',
        descripcion: ''
      });
    }
  };

  const handleFormChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    const message = `Hola, estoy buscando un terreno con las siguientes características:
- Nombre: ${formData.nombre}
- Teléfono: ${formData.telefono}
- Email: ${formData.email}
- Ubicación deseada: ${formData.ubicacion}
- Presupuesto: $${formData.presupuesto}
- Tipo de uso: ${formData.tipoUso}
- Descripción adicional: ${formData.descripcion}`;

    window.open(`https://wa.me/5217221424284?text=${encodeURIComponent(message)}`, '_blank');
  };

  const renderDescriptionForm = () => (
    <Fade in={true} timeout={1000}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: 'auto', borderRadius: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#E91E63', textAlign: 'center', mb: 4 }}>
          Describe el terreno que necesitas
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nombre completo"
              name="nombre"
              value={formData.nombre}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Teléfono"
              name="telefono"
              value={formData.telefono}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Correo electrónico"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Ubicación deseada"
              name="ubicacion"
              value={formData.ubicacion}
              onChange={handleFormChange}
              placeholder="Zona o municipio de preferencia"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Presupuesto aproximado"
              name="presupuesto"
              value={formData.presupuesto}
              onChange={handleFormChange}
              placeholder="Ejemplo: 1,000,000"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Tipo de uso</InputLabel>
              <Select
                name="tipoUso"
                value={formData.tipoUso}
                onChange={handleFormChange}
                label="Tipo de uso"
              >
                {tiposUso.map((tipo) => (
                  <MenuItem key={tipo} value={tipo}>
                    {tipo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Descripción adicional"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleFormChange}
              placeholder="Describe características específicas que buscas en el terreno"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Button
                variant="contained"
                size="large"
                startIcon={<img src={whats} alt="WhatsApp" style={{ width: 24, height: 24 }} />}
                onClick={handleSubmit}
                sx={{
                  backgroundColor: '#25D366',
                  '&:hover': {
                    backgroundColor: '#128C7E'
                  }
                }}
              >
                Enviar solicitud por WhatsApp
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );

  const renderPropertyPreview = (property) => (
    <Paper 
      elevation={3} 
      sx={{ 
        borderRadius: 2, 
        overflow: 'hidden',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-8px)'
        }
      }}
      onClick={() => handlePropertySelect(property)}
    >
      <Box
        sx={{
          height: '300px',
          background: `url(${property.image}) center/cover no-repeat`,
          position: 'relative'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'linear-gradient(transparent, rgba(0,0,0,0.8))',
            color: '#fff',
            p: 3
          }}
        >
          <Typography variant="h5" sx={{ mb: 1 }}>
            {property.title}
          </Typography>
          <Typography variant="subtitle1">
            {property.location}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" color="text.secondary">
          <strong>Superficie:</strong> {property.surface}
        </Typography>
        <Typography variant="h6" sx={{ mt: 2, color: 'primary.main' }}>
          ${property.price_mxn} MXN
        </Typography>
      </Box>
    </Paper>
  );

  const renderPropertyDetail = (property) => (
    <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
      <Box
        sx={{
          height: '400px',
          background: `url(${property.image}) center/cover no-repeat`,
          position: 'relative'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'linear-gradient(transparent, rgba(0,0,0,0.8))',
            color: '#fff',
            p: 3
          }}
        >
          <Typography variant="h4" sx={{ mb: 1 }}>
            {property.title}
          </Typography>
          <Typography variant="h6">
            {property.location}
          </Typography>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Detalles del Predio
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Superficie:</strong> {property.surface}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Descripción:</strong> {property.description}
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Ubicación
        </Typography>
        <Box sx={{ width: '100%', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open("https://earth.google.com/web/data=MkEKPwo9CiExdE54TGtuSlkyRVVWenNVMV9vNkR2ZDlldHhlSGdsYXMSFgoUMDREOEM0N0RFNTM2Q0M0RDdCNUEgAUICCABKCAjMjYSmAhAB", "_blank")}
            sx={{ mr: 2 }}
          >
            Ver en Google Earth
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open("https://www.google.com/maps?q=19.27677099903977,-99.46843133034565", "_blank")}
          >
            Ver en Google Maps
          </Button>
        </Box>
        <Box sx={{ width: '100%', height: 400, mb: 4, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
          <Box 
            component="iframe"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-99.47043133034565%2C19.27477099903977%2C-99.46643133034565%2C19.27877099903977&amp;layer=mapnik&amp;marker=19.27677099903977%2C-99.46843133034565"
            sx={{
              width: '100%',
              height: '100%',
              border: 'none',
              position: 'absolute',
              top: 0,
              left: 0
            }}
            allowFullScreen
            loading="lazy"
            title="Ubicación del Terreno"
          />
        </Box>

        <Typography variant="h6" gutterBottom>
          Precio
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>MXN:</strong> ${property.price_mxn} | <strong>USD:</strong> ${property.price_usd}
        </Typography>
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<img src={whats} alt="WhatsApp" style={{ width: 24, height: 24 }} />}
            onClick={() => window.open(`https://wa.me/5217221424284?text=${encodeURIComponent(property.whatsappMessage)}`, '_blank')}
            sx={{
              backgroundColor: '#25D366',
              '&:hover': {
                backgroundColor: '#128C7E'
              }
            }}
          >
            Contáctame y hablemos
          </Button>
        </Box>
      </CardContent>
    </Paper>
  );

  return (
    <Box component="main" role="main">
      <AppBar 
        component="header"
        position="static" 
        sx={{ 
          backgroundColor: '#0A1929',
          boxShadow: 'none'
        }}
      >
        <Toolbar sx={{ minHeight: '64px' }}>
          {(selectedCategory || selectedProperty) && (
            <IconButton
              edge="start"
              aria-label="Regresar a la página anterior"
              onClick={handleBack}
              sx={{ 
                mr: 1, 
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.08)'
                }
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Box 
            component="img" 
            src={logo} 
            alt="i3plus Logo - Innovación Inmobiliaria Integral"
            sx={{ 
              height: 35,
              mr: 1,
              cursor: 'pointer'
            }}
            onClick={() => window.location.href = '/'}
          />
          <Typography 
            variant="h1" 
            component="h1" 
            sx={{ 
              flexGrow: 1, 
              color: '#fff',
              fontSize: '1rem',
              fontWeight: 400
            }}
          >
            {selectedProperty ? selectedProperty.title : selectedCategory ? selectedCategory : 'Ventas de Terrenos'}
          </Typography>
          <Button 
            component="a"
            href="/"
            aria-label="Ir a la página de inicio"
            sx={{ 
              color: '#fff',
              textTransform: 'none',
              fontSize: '0.875rem',
              minWidth: 'auto',
              padding: '6px 8px',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.08)'
              }
            }}
          >
            Inicio
          </Button>
        </Toolbar>
      </AppBar>

      <Container component="section" maxWidth="lg" sx={{ py: 4 }}>
        {!selectedCategory ? (
          <Fade in={true} timeout={1000}>
            <Box component="section">
              <Typography 
                variant="h2" 
                component="h2" 
                gutterBottom 
                sx={{ 
                  textAlign: 'center', 
                  mb: 6, 
                  color: 'text.primary',
                  fontSize: '2.5rem'
                }}
              >
                Encuentra el terreno ideal para tu proyecto
              </Typography>
              
              <Box component="nav" aria-label="Categorías de terrenos">
                <Grid container spacing={4} justifyContent="center" alignItems="stretch">
                  {categories.map((category) => (
                    <Grid item xs={12} sm={6} md={3} key={category.name}>
                      <Zoom in={true} style={{ transitionDelay: '200ms' }}>
                        <Paper
                          component="article"
                          elevation={3}
                          onClick={() => handleCategorySelect(category.name)}
                          aria-label={`Ver terrenos ${category.name.toLowerCase()}`}
                          sx={{
                            p: 4,
                            height: '100%',
                            textAlign: 'center',
                            cursor: 'pointer',
                            backgroundColor: category.bgColor,
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                              transform: 'translateY(-8px)',
                              boxShadow: 6,
                              backgroundColor: category.bgColor.replace(')', ', 0.8)'),
                            },
                            borderRadius: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2
                          }}
                        >
                          <Box 
                            sx={{ 
                              color: category.color,
                              transform: 'scale(1)',
                              transition: 'transform 0.3s ease',
                              '&:hover': {
                                transform: 'scale(1.1)',
                              }
                            }}
                          >
                            {category.icon}
                          </Box>
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                              color: category.color,
                              fontWeight: 'bold',
                              mb: 1
                            }}
                          >
                            {category.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: 'text.secondary',
                              maxWidth: '80%',
                              mx: 'auto'
                            }}
                          >
                            {category.description}
                          </Typography>
                        </Paper>
                      </Zoom>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box component="section" sx={{ mt: 8, mb: 4 }}>
                <Typography variant="h3" component="h3" align="center" sx={{ mb: 3 }}>
                  ¿No encuentras lo que buscas?
                </Typography>
                <Typography 
                  variant="body1" 
                  component="p"
                  align="center" 
                  color="text.secondary" 
                  sx={{ mb: 6 }}
                >
                  Descríbenos el terreno que necesitas y te ayudamos a encontrarlo
                </Typography>
              </Box>

              <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={8}>
                  <Zoom in={true} style={{ transitionDelay: '400ms' }}>
                    <Paper
                      elevation={3}
                      onClick={() => handleCategorySelect(searchOption.name)}
                      sx={{
                        p: 4,
                        textAlign: 'center',
                        cursor: 'pointer',
                        backgroundColor: searchOption.bgColor,
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-8px)',
                          boxShadow: 6,
                          backgroundColor: searchOption.bgColor.replace(')', ', 0.8)'),
                        },
                        borderRadius: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        position: 'relative',
                        overflow: 'hidden',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: `linear-gradient(45deg, ${searchOption.color}22, transparent)`,
                          zIndex: 0,
                        }
                      }}
                    >
                      <Box 
                        sx={{ 
                          color: searchOption.color,
                          transform: 'scale(1)',
                          transition: 'transform 0.3s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                          },
                          zIndex: 1
                        }}
                      >
                        {searchOption.icon}
                      </Box>
                      <Typography
                        variant="h5"
                        component="h3"
                        sx={{
                          color: searchOption.color,
                          fontWeight: 'bold',
                          mb: 1,
                          zIndex: 1
                        }}
                      >
                        {searchOption.shortName}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'text.secondary',
                          maxWidth: '80%',
                          mx: 'auto',
                          zIndex: 1
                        }}
                      >
                        {searchOption.description}
                      </Typography>
                    </Paper>
                  </Zoom>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        ) : (
          <>
            {selectedCategory === searchOption.name ? (
              <section aria-label="Formulario de búsqueda de terrenos">
                {renderDescriptionForm()}
              </section>
            ) : selectedCategory === 'Residencial' ? (
              <section aria-label="Listado de terrenos residenciales">
                <Grid container spacing={3}>
                  {!selectedProperty ? (
                    residentialProperties.map((property) => (
                      <Grid item xs={12} sm={6} md={4} key={property.id}>
                        <Fade in={true} timeout={1000}>
                          <article>
                            {renderPropertyPreview(property)}
                          </article>
                        </Fade>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Fade in={true} timeout={1000}>
                        <article>
                          {renderPropertyDetail(selectedProperty)}
                        </article>
                      </Fade>
                    </Grid>
                  )}
                </Grid>
              </section>
            ) : (
              <section aria-label={`Terrenos ${selectedCategory.toLowerCase()}`}>
                <Box sx={{ textAlign: 'center', py: 8 }}>
                  <Typography variant="h5" color="text.secondary">
                    Próximamente propiedades {selectedCategory.toLowerCase()}
                  </Typography>
                </Box>
              </section>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default VentasPage;
