import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  AppBar,
  Toolbar
} from '@mui/material';

// Importamos las imágenes locales desde src/images
import casa1 from '../images/casa1.jpg';
import ciudad1 from '../images/ciudad1.jpg';
import terreno2 from '../images/terreno2.jpg';

const LandingPage = () => {
  const [vinedo, setVinedo] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [budget, setBudget] = useState('');
  const [financing, setFinancing] = useState('');
  const [objective, setObjective] = useState('');
  const [buildingTime, setBuildingTime] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactData = {
      vinedo,
      property_type: propertyType,
      budget,
      financing,
      objective,
      building_time: buildingTime,
      name,
      email,
      phone,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/contact-leads/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(contactData),
        }
      );

      if (!response.ok) {
        throw new Error('Error al enviar la información');
      }

      setVinedo('');
      setPropertyType('');
      setBudget('');
      setFinancing('');
      setObjective('');
      setBuildingTime('');
      setName('');
      setEmail('');
      setPhone('');
      setMessage('¡Gracias! Pronto nos pondremos en contacto.');
    } catch (error) {
      setMessage('Hubo un error al enviar la información. Inténtalo de nuevo.');
    }
  };

  return (
    <>
      {/* Menú de navegación robustecido */}
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            3iplus Luxury Estates
          </Typography>
          <Button onClick={() => window.location.href = '/ventas'} color="inherit">
            Ventas
          </Button>
        </Toolbar>
      </AppBar>

      {/* Sección Hero con imagen de fondo (usamos ciudad1 como ejemplo) */}
      <Box
        sx={{
          height: '80vh',
          background: `url(${ciudad1}) center/cover no-repeat`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {/* Overlay translúcido */}
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          }}
        />
        {/* Contenido principal */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(6px)',
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
            maxWidth: 600,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#fff',
              fontWeight: 300,
              mb: 2,
              fontSize: { xs: '2.5rem', md: '3rem' },
            }}
          >
            3iplus Luxury Estates
          </Typography>
          <Typography variant="h6" sx={{ color: '#fafafa', mb: 3 }}>
            Vive la experiencia premium en nuestros exclusivos desarrollos.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              const contactoSection = document.getElementById('contacto');
              if (contactoSection) {
                contactoSection.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            Contáctanos
          </Button>
        </Box>
      </Box>

      {/* Sección de Beneficios / Highlights */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" sx={{ mb: 4 }}>
          ¿Por qué invertir con nosotros?
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                height="220"
                image={casa1}
                alt="Arquitectura Minimalista"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Arquitectura Minimalista
                </Typography>
                <Typography variant="body1">
                  Diseños de vanguardia con acabados de lujo que reflejan un
                  estilo de vida sofisticado.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                height="220"
                image={terreno2}
                alt="Entorno Privilegiado"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Entorno Privilegiado
                </Typography>
                <Typography variant="body1">
                  Paisajes únicos rodeados de viñedos, gastronomía de autor y
                  amenidades premium.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                height="220"
                image={ciudad1}
                alt="Experiencia Exclusiva"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Experiencia Exclusiva
                </Typography>
                <Typography variant="body1">
                  Vistas inigualables y la tranquilidad de un ambiente seguro en
                  armonía con la naturaleza.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Sección de Contacto */}
      <Box id="contacto" sx={{ py: 6, backgroundColor: '#f4f4f4' }}>
        <Container maxWidth="sm">
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Solicita Información
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
          >
            {message && (
              <Alert
                severity={
                  message.includes('Gracias') ? 'success' : 'error'
                }
              >
                {message}
              </Alert>
            )}

            <FormControl fullWidth required>
              <InputLabel>¿Viñedo interesado?</InputLabel>
              <Select
                value={vinedo}
                label="¿Viñedo interesado?"
                onChange={(e) => setVinedo(e.target.value)}
              >
                <MenuItem value="Viñedos Santisima Trinidad">
                  Viñedos Santísima Trinidad
                </MenuItem>
                <MenuItem value="Viñedos San Lucas">Viñedos San Lucas</MenuItem>
                <MenuItem value="Viñedos San Francisco">
                  Viñedos San Francisco
                </MenuItem>
                <MenuItem value="Viñedos de los Senderos">
                  Viñedos de los Senderos
                </MenuItem>
                <MenuItem value="Viñedos Santa Catalina">
                  Viñedos Santa Catalina
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required>
              <InputLabel>¿Te interesa un(a)?</InputLabel>
              <Select
                value={propertyType}
                label="¿Te interesa un(a)?"
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <MenuItem value="Terreno">Terreno</MenuItem>
                <MenuItem value="Casa">Casa</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required>
              <InputLabel>¿Cuál es tu presupuesto?</InputLabel>
              <Select
                value={budget}
                label="¿Cuál es tu presupuesto?"
                onChange={(e) => setBudget(e.target.value)}
              >
                <MenuItem value="Entre 5 y 7.5 millones">
                  Entre 5 y 7.5 millones
                </MenuItem>
                <MenuItem value="Entre 7.5 y 10 millones">
                  Entre 7.5 y 10 millones
                </MenuItem>
                <MenuItem value="Arriba de 10 millones">
                  Arriba de 10 millones
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required>
              <InputLabel>¿Requieres financiamiento?</InputLabel>
              <Select
                value={financing}
                label="¿Requieres financiamiento?"
                onChange={(e) => setFinancing(e.target.value)}
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Tal vez">Tal vez</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required>
              <InputLabel>¿Cuál es el objetivo de compra?</InputLabel>
              <Select
                value={objective}
                label="¿Cuál es el objetivo de compra?"
                onChange={(e) => setObjective(e.target.value)}
              >
                <MenuItem value="Inversión">Inversión</MenuItem>
                <MenuItem value="Casa de fin de semana">
                  Casa de fin de semana
                </MenuItem>
                <MenuItem value="Para vivir">Para vivir</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required>
              <InputLabel>¿En cuánto tiempo piensas construir?</InputLabel>
              <Select
                value={buildingTime}
                label="¿En cuánto tiempo piensas construir?"
                onChange={(e) => setBuildingTime(e.target.value)}
              >
                <MenuItem value="1 año">1 año</MenuItem>
                <MenuItem value="Entre 2 y 5 años">Entre 2 y 5 años</MenuItem>
                <MenuItem value="No pienso construir por ahora">
                  No pienso construir por ahora
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Nombre Completo"
              variant="outlined"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              label="Correo Electrónico"
              variant="outlined"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              label="Teléfono  de Contacto"
              variant="outlined"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button type="submit" variant="contained" size="large">
                Enviar
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
